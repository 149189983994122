import React from 'react'

export const AnimateLetters = ({ text, triggerFade }) => (
    text.split('').map((char, i) => {
      const style = {'animationDelay': (i / 20) + 's'}
      if (i === text.length - 1) setTimeout(() => triggerFade(), 1000 * (i / 16))
      return (
        <span key={i} style={style} >{char}</span>
      )
    })
  )