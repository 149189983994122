import React from 'react'
import { AnimateLetters } from '../helpers/AnimateLetters'

import { FaArrowDown } from 'react-icons/fa'

const Home = ({ homeRef, fadeIn, triggerFade }) => {

  return (
    <section ref={homeRef} className='home-container'>
      <img src='https://i.imgur.com/WI1didT.jpg' alt='jonny-vickers' className={fadeIn ? 'profile-photo fade' : 'profile-photo'}/>
      <div className='home-text-container'>
        <h1><AnimateLetters text='Hi, I&apos;m Jonny Vickers' triggerFade={triggerFade}/></h1>
        <h2 className={fadeIn ? 'fade' : ''}>I'm a Junior Full Stack Developer based in London</h2>
        <FaArrowDown className={fadeIn ? 'fade' : ''}/>
      </div>

    </section>
  )
}

export default Home