import React from 'react'

import { FiMail } from 'react-icons/fi'

const Contact = ({ contactRef }) => {

  return (
    <section ref={contactRef} className='contact-container'>
      <h2>Contact me</h2>

      <div className='icon-wrapper'>
        <div className='contact-wrapper'>
          <a href='mailto:jonathanbvickers@gmail.com' rel='noreferrer' target='_blank'><FiMail /></a>
        </div>

        <div className='contact-wrapper'>
          <a href='https://www.linkedin.com/in/jonny-vickers/' rel='noreferrer' target='_blank'><i className='devicon-linkedin-plain' /></a>
        </div>

        <div className='contact-wrapper'>
          <a href='https://github.com/jvickers123' rel='noreferrer' target='_blank'><i className='devicon-github-original' /></a>
        </div>

      </div>

      
    </section>
  )
}

export default Contact