import React from 'react'
import { FaExternalLinkAlt } from 'react-icons/fa'

const Projects = ({ projectRef }) => {

  return (
    <section ref={projectRef}>
      <h2>Projects</h2>

      <div className='projects-container'>

        <div className='project-wrapper'>
          <div className='screenshot-wrapper'>
            <img src='https://i.imgur.com/YJC7WDS.png' alt='birds-of-a-feather' />
          </div>
          <div className='project-text-wrapper'>
            <h3>Birds of a Feather</h3>
            <p>9 days || Solo</p>
            <p>Full stack app with CRUD functionality using Python, Django and React. I built a dating app in which users could answer multiple ‘would you rather’ questions and be matched with other profiles based on their answers.</p>
          </div>

          <div className='links-wrapper'>
              <a href='https://birds-of-a-feather.herokuapp.com/' rel='noreferrer' target='_blank'><FaExternalLinkAlt /> View Site</a>
              <a href='https://github.com/jvickers123/sei-project-four' rel='noreferrer' target='_blank'><i className='devicon-github-original' /> GitHub</a>
            </div>
        </div>

        <div className='project-wrapper'>
          <div className='screenshot-wrapper'>
            <img src='https://i.imgur.com/WXU1sNN.png' alt='holiday-mates' />
          </div>
          <div className='project-text-wrapper'>
            <h3>Holiday Mates</h3>
            <p>9 days || Group</p>
            <p>Full stack MERN app with CRUD functionality. We built a social media site for sharing holidays. On the back end, I focussed on referencing between the databases and the add friend functionality. For the front end, I focussed on the display, search and filter of the holidays on an interactive map and the profile and friends pages.</p>
          </div>

          <div className='links-wrapper'>
              <a href='https://holidaymates.herokuapp.com/' rel='noreferrer' target='_blank'><FaExternalLinkAlt /> View Site</a>
              <a href='https://github.com/jvickers123/Holiday_Mates'  rel='noreferrer' target='_blank'><i className='devicon-github-original' /> GitHub</a>
            </div>
        </div>

        

        <div className='project-wrapper'>
          <div className='screenshot-wrapper'>
            <img src='https://i.imgur.com/8KtXLig.png' alt='movie-search' />
          </div>
          <div className='project-text-wrapper'>
            <h3>Movie Search</h3>
            <p>48 hours || Pair</p>
            <p>Pair-coded an app using React that used an external API, in which users could find information on films. I particularly focussed on the searching functionality and display of results.</p>
          </div>
          <div className='links-wrapper'>
              <a href='https://jonnyalexfilmsearch123.netlify.app/' rel='noreferrer' target='_blank'><FaExternalLinkAlt /> View Site</a>
              <a href='https://github.com/jvickers123/sei-project-two' rel='noreferrer' target='_blank'><i className='devicon-github-original' /> GitHub</a>
            </div>
        </div>

        <div className='project-wrapper'>
          <div className='screenshot-wrapper'>
            <img src='https://i.imgur.com/O9qaIPc.png' alt='pac-man' />
          </div>
          <div className='project-text-wrapper'>
            <h3>Pac-Man</h3>
            <p>9 days || solo</p>
            <p>I used JavaScript to build a grid based Pac-Man game with three levels, adjustable difficulty and a leaderboard. I was particularly proud of creating a function that helped me build levels and ensuring the ghosts moved towards Pac-Man.</p>
          </div>
          <div className='links-wrapper'>
              <a href='https://jvickers123.github.io/sei-project-one-pac-man/' rel='noreferrer' target='_blank'><FaExternalLinkAlt /> View Site</a>
              <a href='https://github.com/jvickers123/sei-project-one-pac-man' rel='noreferrer' target='_blank'><i className='devicon-github-original' /> GitHub</a>
            </div>
        </div>


      </div>



    </section>
  )
}

export default Projects