import React from 'react'

import { SiChakraui, SiInsomnia } from 'react-icons/si'
import { BsFillBootstrapFill } from 'react-icons/bs'

const AboutMe = ({ aboutRef}) => {

  return (
    <section ref={aboutRef} className='about-me-wrapper'>

      <div className='about-me-text-wrapper'>
        <h2>About Me</h2>
        <p>I spent a few years touring the UK and Europe as a musician and manager for an indie pop band, before going off to university and becoming a teaching assistant in a behaviour unit for teenagers. Whilst I enjoyed many aspects, I found I craved the opportunity to apply myself in a way that fully utilised creative, organisation and problem solving skills.</p>
        <p>Once a friend corrected my misconception that it was too late to pursue a career in software, I tried my hand at some basic coding courses online and have never looked back, quickly signing up to a full time software engineering bootcamp at General Assembly.</p>
        <p>I am looking for a dynamic environment to throw myself into and continue developing my software engineering skills and knowledge.</p>
      </div>
      
      <div className='skills-container'>
        <h2>Skills</h2>

        <h3>Front End</h3>

        <div className='skills-wrapper'>

          <div className='icon-wrapper'>
            <i className='devicon-javascript-plain' />
            <p>JavaScript</p>
          </div>

          <div className='icon-wrapper'>
            <i className="devicon-css3-plain"/>
            <p>CSS</p>
          </div>

          <div className='icon-wrapper'>
            <i className='devicon-html5-plain' />
            <p>HTML</p>
          </div>

          <div className='icon-wrapper'>
            <i className='devicon-react-original' />
            <p>React</p>
          </div>

          <div className='icon-wrapper'>
            <i className='devicon-sass-original' />
            <p>SASS</p>
          </div>

          <div className='icon-wrapper'>
            <SiChakraui />
            <p>Chakra</p>
          </div>

          <div className='icon-wrapper'>
            <BsFillBootstrapFill />
            <p>Bootstrap</p>
          </div>
        </div>

        <h3>Back End</h3>

        <div className='skills-wrapper'>

          <div className='icon-wrapper'>
            <i className='devicon-python-plain' />
            <p>Python</p>
          </div>

          <div className='icon-wrapper'>
            <i className="devicon-nodejs-plain"/>
            <p>Node.js</p>
          </div>

          <div className='icon-wrapper'>
            <i className='devicon-mongodb-plain' />
            <p>MongoDB</p>
          </div>

          <div className='icon-wrapper'>
            <i className='devicon-express-original' />
            <p>Express</p>
          </div>

          <div className='icon-wrapper'>
            <i className='devicon-mongodb-plain' />
            <p>Mongoose</p>
          </div>

          <div className='icon-wrapper'>
            <i className='devicon-django-plain' />
            <p>Django</p>
          </div>

          <div className='icon-wrapper'>
            <i className='devicon-postgresql-plain'/>
            <p>PostgreSQL</p>
          </div>

          <div className='icon-wrapper'>
            <SiInsomnia />
            <p>Insomnia</p>
          </div>

        </div>

        <h3>Storage, Package and Version Control</h3>

        <div className='skills-wrapper'>

        <div className='icon-wrapper'>
            <i className='devicon-yarn-plain' />
            <p>Yarn</p>
          </div>

          <div className='icon-wrapper'>
            <i className="devicon-npm-original-wordmark"/>
            <p>npm</p>
          </div>

        <div className='icon-wrapper'>
            <i className='devicon-github-original' />
            <p>GitHub</p>
          </div>

          <div className='icon-wrapper'>
            <i className='devicon-git-plain'/>
            <p>Git</p>
          </div>

        <div className='icon-wrapper'>
            <i className='devicon-heroku-original' />
            <p>Herou</p>
          </div>
          
        </div>
      </div>

    </section>
  )
}

export default AboutMe