import React from 'react'

import { FaSpotify } from 'react-icons/fa'
import { SiApplemusic, SiApplepodcasts } from 'react-icons/si'

const Interests = ({ interestRef}) => {

  return (
    <section ref={interestRef} className='interests-container'>
      <h2>Interests</h2>

      <div className='interests-main-flex-wrapper'>

        <div className='interest-wrapper'>
          <h3>Music</h3>
          <div className='interest-body-wrapper'>
            <img src='https://i.imgur.com/biOYw7m.png' alt='album-cover'></img>
            <div className='interest-text'>
            <p>I play guitar, record and produce songs in a band called Shotover. We recently released an EP which got some local radio play.</p>

            <div className='interest-links-wrapper'>
              <a href='https://open.spotify.com/album/5RuY35LQ6fe8fWf0HEIxko?si=SlxW7712SoSpbswZ1fYpLg' rel='noreferrer' target='_blank'><FaSpotify />Spotify</a> 
              <a href='https://music.apple.com/gb/album/kill-everything-now-ep/1608272910' rel='noreferrer' target='_blank'><SiApplemusic /> Apple Music</a>
            </div>
            </div>
          </div>

        </div>

        <div className='interest-wrapper'>
          <h3>Football</h3>
          <div className='interest-body-wrapper'>
            <img src='https://i.imgur.com/PMz9mvi.png' alt='podcast-cover'></img>
            <div className='interest-text'>
              <p>I play football every week and release a very sporadic podcast about all things football with some friends.</p>
              <div className='interest-links-wrapper'>
                <a href='https://open.spotify.com/show/2WwJdMOu2KKugN1KwcOevY'><FaSpotify /> Spotify</a>
                <a href='https://podcasts.apple.com/gb/podcast/in-air-plane-repair/id1434094786'> <SiApplepodcasts /> Apple Podcasts</a>
              </div>
            </div>
          </div>


        </div>
      </div>


    </section>
  )
}

export default Interests