import React, { useState } from 'react'

import { BiMenu } from "react-icons/bi";

const SiteNavBar = ({ homeRef, aboutRef, projectRef, interestRef, contactRef, fadeIn}) => {

  const [showOptions, setShowOptions] = useState(false)

  const toggleOptions =() => {
    showOptions === true ? setShowOptions(false) : setShowOptions(true)
  }

  const handleClick = (ref) => {
    setShowOptions(false)
    ref.current.scrollIntoView({ behavior: 'smooth'})
  }

  return (
    <div className={fadeIn ? 'navigation-bar-wrapper slide-in' : 'navigation-bar-wrapper'}>
      <button onClick={() => homeRef.current.scrollIntoView({ behavior: 'smooth' })} >Jonny Vickers</button>
      <button onClick={toggleOptions} className='nav-options-btn'><BiMenu /></button>
      
      <div className={showOptions ? 'nav-bar-options-wrapper show': 'nav-bar-options-wrapper hide'} >
        <button onClick={() => handleClick(aboutRef)} >About Me</button>
        <button onClick={() => handleClick(projectRef)} >Projects</button>
        <button onClick={() => handleClick(interestRef)} >Interests</button>
        <button onClick={() => handleClick(contactRef)}>Contact</button>
      </div>
    </div>
  )
}

export default SiteNavBar