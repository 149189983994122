import React, { useRef, useState } from 'react'

// COMPONENTS
import Home from './components/Home';
import SiteNavBar from './components/SiteNavBar';
import AboutMe from './components/AboutMe';
import Projects from './components/Projects';
import Interests from './components/Interests';
import Contact from './components/Contact';

function App() {
  const homeRef = useRef(null)
  const aboutRef = useRef(null)
  const projectRef = useRef(null)
  const interestRef = useRef(null)
  const contactRef = useRef(null)
  
  const [fadeIn, setFadeIn] = useState(false)
  

  const triggerFade = () => {
    setFadeIn(true)
  }

  return (
    <>
      <SiteNavBar 
        homeRef={homeRef}
        aboutRef={aboutRef}
        projectRef={projectRef}
        interestRef={interestRef}
        contactRef={contactRef}
        fadeIn={fadeIn}
      />

      <Home homeRef={homeRef} triggerFade={triggerFade} fadeIn={fadeIn}/>
      <AboutMe aboutRef={aboutRef}/>
      <Projects projectRef={projectRef}/>
      <Interests interestRef={interestRef}/>
      <Contact contactRef={contactRef}/>
    </>
  )
}

export default App;
